import { Component, OnInit, Host } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';

// const md5 = require('md5');
@Component({
  selector: 'app-ftp',
  templateUrl: './ftp.component.html',
  styleUrls: ['./ftp.component.css']
})
export class FtpComponent implements OnInit {

  formFtp = new FormGroup({
    host: new FormControl(),
    puerto: new FormControl(),
    usuario: new FormControl(),
    password: new FormControl(),
  })

  host: any
  puerto
  user
  pass
  constructor(private servicio: ConfiguracionService) { }

  ngOnInit() {
    this.servicio.getFtp().subscribe(data => {
      const temp: any = data
      console.log('eeee ', data)
      console.log('eeee ', temp.host)
      this.host = temp.host
      this.puerto = temp.puerto
      this.user = temp.usuario
      this.pass = temp.password
      this.formFtp.setValue(temp)
    })
  }

  onSubmit() {
    const temp: any = this.formFtp.value
    // temp.password = md5(temp.password)
    this.servicio.setFtp(temp).then(t => {

    }).catch(err => {

    })
  }
}
