import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFirebaseService } from 'src/app/servicios/auth-firebase.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user = ''
  pass = ''
  error = false
  constructor( private router: Router, private authF: AuthFirebaseService) { }

  ngOnInit() {
  }
  login() {
    // this.servicio.login(this.user).subscribe(data => {
    //   if (data.length === 1) {
    //     const t: any = data[0]
    //     if (t.pass === this.pass) {
    //       this.servicio.setUser(t)
    //     }
    //   }
    // })
    const user = this.authF.loginEmail(this.user, this.pass).then( e => { this.router.navigate( ['/admin'] )})
    // if ( user) {
    //   this.router.navigate(['/admin'])
    // }
  }
}
