
export class User {
  email:    string;
  uid: string;
  rol: any;

  constructor(authData) {
    this.email    = authData.email
    this.uid = authData.uid
  }
}
