import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';

//const md5 = require('md5');

@Component({
  selector: 'app-email-e',
  templateUrl: './email-e.component.html',
  styleUrls: ['./email-e.component.css']
})
export class EmailEComponent implements OnInit {
  formEmail = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
  })
  email
  pass
  constructor(private servicio: ConfiguracionService) { }

  ngOnInit() {
    this.servicio.getEmailE().subscribe(data => {
      const temp: any = data
      this.email = temp.email
      this.pass = temp.password
      this.formEmail.setValue(data)
    })
  }

  onSubmit() {
    const temp: any = this.formEmail.value
    // temp.password = md5(temp.password)
    this.servicio.setEmailE(temp).then(t => {

    }).catch(err => {

    })
  }
}
