import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fire from 'firebase/app';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { FirebaseAuth } from '@angular/fire';


export interface Cliente {
  codigo: string;
  email: string;
  nombre: string;
  uid: string;
}
@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  respCollection: AngularFirestoreCollection<any>;
  reps: Observable<any[]>;
  tempapp
  tempadmin
  constructor(private afs: AngularFirestore, private http: HttpClient) {

    this.tempapp = fire.initializeApp(environment.firebase, 'temp')
  }

  agregarcliente(nombre, email, codigo, rol) {
    // return this.tempapp.auth().createUserWithEmailAndPassword(email, password).then(data => {
    //   console.log(data.user.uid)
    // console.log(nombre, email, codigo)
    return this.afs.collection('users').add({
      codigo: codigo,
      nombre: nombre,
      email: email,
      // uid: data.user.uid,
      rol: rol,
      dispositivos: {},

    })
    //   this.tempapp.auth().signOut();
    // }).catch(error => {
    //   // Handle Errors here.
    //   console.log(error.code);
    //   console.log(error.message);
    //   // ...
    // });
  }

  async actualizarClient(nombre, email, codigo, rol, codigoEdit) {
    try {
      const snapshot = await this.afs.collection('users').ref.where('codigo', '==', codigoEdit).get();

      if (snapshot.empty) {
        throw new Error('Usuario no encontrado');
      }

      const userDoc = snapshot.docs[0];
      const userData = userDoc.data();

      const uid = userData.uid;
      const url = 'https://us-central1-fotos-productos-pvm.cloudfunctions.net/generateCustomToken';
      const customTokenResponse = await this.http.post<{ customToken: string }>(url, { uid }).toPromise();

      const tempAuth = this.tempapp.auth();
      const tempUserCredential = await tempAuth.signInWithCustomToken(customTokenResponse.customToken);

      if (!tempUserCredential.user) {
        throw new Error('No se pudo autenticar al usuario temporalmente');
      }

      await tempUserCredential.user.updateProfile({
        displayName: nombre
      });

      if (userData.email !== email) {
        await tempUserCredential.user.verifyBeforeUpdateEmail(email);
      }
      
      await userDoc.ref.update({
        codigo: codigo,
        nombre: nombre,
        email: email,
        rol: rol,
      });

      return 'Actualización exitosa';
    } catch (error) {
      console.error('Error al actualizar el cliente:', error);
      throw new Error('No se pudo actualizar el cliente. Por favor, intente de nuevo más tarde.');
    }
  }


  // getClientes() {
  //   return this.afs.collection('users').snapshotChanges().pipe(map(actions => actions.map(a => {
  //     const data = a.payload.doc.data()
  //     const id = a.payload.doc.id
  //     return { id, ...data }
  //   })))
  // }
  getClientes2() {
    return this.afs.collection('users').valueChanges()
  }
  delete(codigo) {
    return this.afs.collection('users').ref.where('codigo', '==', codigo).get().then(e => {

      // const url = `http://localhost:5000/test-productos/us-central1/deleteUser`
      const url = `https://us-central1-fotos-productos-pvm.cloudfunctions.net/deleteUser`
      const body = new HttpParams()
        .set('uid', e.docs[0].data().uid)

      this.http.post(url, body, { responseType: 'text' }).subscribe(data => {
        // console.log('-+-', data);
        e.docs[0].ref.delete()
      },
        response => {
          // console.log('POST call in error delete', response);
        },
        () => {
          // console.log('The POST observable is now completed  delete.');
        });

    })
  }
  getconfig() {
    return this.afs.collection('app-config').doc('strings').valueChanges()
    // return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  }
  settextolegal(texto) {
    return this.afs.collection('app-config').doc('strings').update({ textoLegal: texto })

  }
  sendmail(email, asunto, mensaje) {
    // console.log('---------', email, mensaje)

    // const url = `http://localhost:5000/test-productos/us-central1/sendMailCliente`
    const url = `https://us-central1-fotos-productos-pvm.cloudfunctions.net/sendMailCliente`
    const body = new HttpParams()
      .set('email', email)
      .set('mensaje', mensaje)
      .set('asunto', asunto)

    this.http.post(url, body, { responseType: 'text' }).subscribe(data => {
      // console.log('-+-', data);
    },
      response => {
        // console.log('POST call in error sendmail', response);
      },
      () => {
        // console.log('The POST observable is now completed enviar mensaje.');
      });
  }

  registrarUsuario(codigo, email, contraseña, cab) {
    return this.tempapp.auth().createUserWithEmailAndPassword(email, contraseña).then(data => {
      // console.log(data.user.uid)
      this.afs.collection('users').ref.where('codigo', '==', codigo).get().then(e => {
        // console.log(e.docs[0].data().nombre)
        e.docs[0].ref.delete()
        this.afs.collection('users').doc(data.user.uid).set({
          nombre: e.docs[0].data().nombre,
          email: e.docs[0].data().email,
          codigo: e.docs[0].data().codigo,
          rol: e.docs[0].data().rol,
          dispositivos: {},
          uid: data.user.uid,
        })
        data.user.updateProfile({
          displayName: e.docs[0].data().nombre
        })
        cab()
      })
      this.tempapp.auth().signOut();
      // this.afs.collection('users').ref.where('codigo', '==', codigo).get().then(e => {
      //   e.docs[0].ref.update({ uid: data.user.uid })
      //   console.log('actualizar uid')
      //   console.log(cab)
      //   cab()
      // })
    }).catch(error => {
      // Handle Errors here.
      // console.log('dddddd', error.code);
      // console.log(error.message);
      if ('auth/email-already-in-use' === error.code) {
        // console.log('cambiar contraseña')
        this.afs.collection('users').ref.where('codigo', '==', codigo).get().then((e: any) => {
          ///////////////
          // const url = `http://localhost:5000/test-productos/us-central1/updatePassword`
          const url = `https://us-central1-fotos-productos-pvm.cloudfunctions.net/updatePassword`
          const body = new HttpParams()
            .set('uid', e.docs[0].data().uid)
            .set('password', contraseña)
          // console.log(e.docs[0].data().uid, contraseña)
          this.http.post(url, body, { responseType: 'text' }).subscribe(data => {
            // console.log('-+-', data);
            cab()
          },
            response => {
              // console.log('POST call in error updatepassword', response);
              //// cab() temporal mientras el cors no esta en produccion
              // cab();
            },
            () => {
              // console.log('The POST observable is now completed registrar usuario.');
            });
        })

      }
      // ...
    });
  }
}
