import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClientesService } from 'src/app/servicios/clientes.service';
import { MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { PopupComponent } from '../popup/popup.component';

import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

declare var $: any;

export interface Users {
  nombre: string;
  email: string;
  uid: string;
  codigo: string;
  rol: string;
}
// tslint:disable-next-line:prefer-const
let users_data: any[];

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {
  globalFilter = ''
  displayedColumns: string[] = ['nombre', 'email', 'codigo', 'rol', 'ultAcceso',  'accion'];
  // dataSource = new MatTableDataSource(users_data);
  dataSource: MatTableDataSource<any>
  textolegal = ''
  nombre = ''
  email = ''
  codigo = ''
  rol = ''
  ultAcceso = ''
  editt = false
  codigoeditt
  editlegal = true

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private servicio: ClientesService, public dialog: MatDialog) { }
  dispositivos(codigo) {
    const temp = this.dataSource.data
    temp.forEach(element => {
      if (element.codigo === codigo) {

        const disp = element.dispositivos
        // console.log(disp )
        if (disp) {
          const dialogRef = this.dialog.open(PopupComponent, {
            data: disp,
            width: '550px',
          });

          dialogRef.afterClosed().subscribe(result => {
            // console.log(`Dialog result: ${result}`);
          });
        } else {
          alert('no tiene dispositivos registrados')
        }
      } else {
        console.log('')

      }
    })
  }


  ngOnInit() {

    this.servicio.getClientes2().subscribe(data => {

      data = data.filter((e: any) => {
        return (e.rol === 'usuario' || e.rol === undefined || e.rol === 'admin' || e.rol === 'asistente' || e.rol === 'cash');
      })
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.data = this.agregarUltimoAcceso(this.dataSource.data);
      console.log('sssssssssssssssssssss', this.dataSource.data)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // console.log('------------------', this.dataSource.sort)
    })
    this.servicio.getconfig().subscribe((data: any) => {
      // console.log(data)
      this.textolegal = data.textoLegal
    })
  }

  toDate(seconds) {
    return new Date(seconds * 1000)
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  agregarUltimoAcceso(data) {
    data.forEach(usuario => {
      const dispositivos = usuario.dispositivos;
      let ultimoAcceso = null;
      for (const key in dispositivos) {
        if (dispositivos.hasOwnProperty(key)) {
          const dispositivo = dispositivos[key];
          const accesoActual = dispositivo.fechaUltimoAcceso;
          if (!ultimoAcceso) {
            ultimoAcceso = accesoActual;
          } else {
            if (accesoActual.seconds > ultimoAcceso.seconds ||
              (accesoActual.seconds === ultimoAcceso.seconds && accesoActual.nanoseconds > ultimoAcceso.nanoseconds)) {
              ultimoAcceso = accesoActual;
            }
          }
        }
      }
      usuario.ultAcceso = ultimoAcceso;
    });
    return data;
  }

  agregarCliente() {
    if (this.validarEmail()) {
      // console.log(this.nombre, ' ', this.email, ' ', this.codigo)
      if (this.codigo !== '' && !this.ifcodigo(this.codigo)  ) {
        this.servicio.agregarcliente(this.nombre, this.email, this.codigo, this.rol).then(data => {
          this.clear()
        })
        // console.log('dddd')
      } else {
        $('#codigoErr').css('border-top', 'solid red')
        setTimeout(() => {
          $('#codigoErr').css('border-top', '0px solid transparent')
        }, 2000);
        alert('El codigo ya existe o es invalido')
      }
    } else {
      $('#emailErr').css('border-top', 'solid red')
      setTimeout(() => {
        $('#emailErr').css('border-top', '0px solid transparent')
      }, 2000);
      alert('email invalido')
    }
  }

  editar(codigo) {
    // console.log('editar ' + codigo)
    const temp = this.dataSource.data
    temp.forEach(element => {
      if (element.codigo === codigo) {

        this.nombre = element.nombre
        this.email = element.email
        this.codigo = element.codigo
        this.rol = element.rol
        this.editt = true
        this.codigoeditt = codigo
      }
    })


    // this.servicio.editarCliente((this.nombre, this.email, this.codigo)
  }
  cancelar() {
    // console.log('cancelar')
    this.nombre = ''
    this.email = ''
    this.codigo = ''
    this.codigoeditt = ''
    this.editt = false
  }
  actualizar(codigo) {

    // console.log('actualizar', this.codigoeditt)
    this.servicio.actualizarClient(this.nombre, this.email, this.codigo, this.rol, this.codigoeditt).then(e => {
      this.editt = false
      this.limpiarCampos()
    }).catch(c => {
      console.log(c)
    })

    //  .then(data => {
    //     console.log(data)
    //   })

  }
  limpiarCampos() {
    // console.log('limpiar')
    this.nombre = ''
    this.email = ''
    this.codigo = ''
    this.codigoeditt = ''
    return ''
  }
  eliminar(codigo) {
    const r = confirm('¿Esta seguro de eliminar?')
    if (r === true) {
      // console.log('eliminar ' + codigo)
      this.servicio.delete(codigo)
    } else {
    }
  }
  enviarMail(codigo) {
    const r = confirm('¿Esta seguro de enviar mail?')
    if (r === true) {
      // console.log('enviando ' + codigo)
      const temp = this.dataSource.data
      temp.forEach(element => {
        if (element.codigo === codigo) {

          const nombre = element.nombre
          const sendemail = element.email
          const contraseña = this.generatePassword()
          let urlDesc = ''
          let textL = ''
          let mensaje = ''
          let asunto = ''
          let textohola = ''
          let textomail1 = ''
          let textomail2 = ''
          let textomail3 = ''
          // tslint:disable-next-line:no-unused-expression
          this.servicio.getconfig().subscribe((data: any) => {
            // console.log(data)
            urlDesc = data.urlDownload
            textL = data.textoLegal
            textohola = data.hola
            asunto = data.mailSubject
            textomail1 = data.textomail1
            textomail2 = data.textomail2
            textomail3 = data.textomail3
            mensaje = `${textohola}, ${nombre} \n
            ${textomail1} \n
            ${textomail2}
            ${urlDesc}\n
            ${textomail3} ${contraseña}\n
            ${textL}`

            // console.log(mensaje)
            this.servicio.registrarUsuario(codigo, sendemail, contraseña, (() => { this.servicio.sendmail(sendemail, asunto, mensaje) }))
          })
        }
      })
    } else {

    }
  }
  editarLegal() {
    // console.log('editar legal')
    document.getElementById('legal').removeAttribute('disabled')
    this.editlegal = false

  }
  cambiarTextoLegal() {
    // console.log('cambiartextoleal')
    this.servicio.settextolegal(this.textolegal).then(e => {
      this.editlegal = true
      document.getElementById('legal').setAttribute('disabled', 'disabled');
    })

  }
  cancelarTextoLegal() {
    // console.log('cancelar edit legal')
    document.getElementById('legal').setAttribute('disabled', 'disabled');
    this.editlegal = true
    this.servicio.getconfig().subscribe((data: any) => {
      // console.log(data)
      this.textolegal = data.textoLegal
    })
  }
  exportarCsv() {
    const data = new Array();
    this.dataSource.data.forEach(d => {
      // tslint:disable-next-line:max-line-length
      data.push({ Nombre: d.nombre, Email: d.email, Codigo: d.codigo })
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      noDownload: false,
      headers: ['Nombre', 'Email', 'Codigo']
    };
    // tslint:disable-next-line:no-unused-expression
    new Angular5Csv(data, 'Lista CLientes', options)
  }
  clear() {
    this.nombre = ''
    this.email = ''
    this.codigo = ''
  }
  generatePassword() {
    const caracteres = 'abcdefghijkmnpqrtuvwxyz123456789';
    let contraseña = '';
    for (let i = 0; i < 9; i++) { contraseña += caracteres.charAt(Math.floor(Math.random() * caracteres.length)) };
    return contraseña;
  }

  validarEmail() {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(this.email).toLowerCase())
  }

  ifcodigo(codigo) {
    const temp = this.dataSource.data
    let resp = false
    temp.forEach(element => {
      // console.log(element.codigo, codigo)
      if (element.codigo === codigo) {
        resp = true
      }
    })
    return resp
  }
}

