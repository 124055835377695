import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { VersionesService } from 'src/app/servicios/versiones.service';

declare var $: any;

@Component({
  selector: 'app-versiones',
  templateUrl: './versiones.component.html',
  styleUrls: ['./versiones.component.css']
})
export class VersionesComponent implements OnInit {
  apkFilename; id; pubDate; idedit;
  valid = true
  editt = false

  globalFilter = ''
  displayedColumns: string[] = ['apkFilename', 'id', 'pubDate', 'valid', 'accion'];
  // dataSource = new MatTableDataSource(users_data);
  dataSource: MatTableDataSource<any>

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private servicio: VersionesService) { }

  ngOnInit() {
    this.servicio.getVersiones().subscribe(data => {
      // console.log(data)
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  agregarVersion() {
    // console.log(this.pubDate)
    const temp = this.dataSource.data
    const t = temp.filter(data => (data.id == this.id));
    if (t.length === 1) {
      // console.log('ya existe el ID')
      alert('ya existe el ID')
    } else {
      this.servicio.agregarVersion(this.apkFilename, this.id, new Date(this.pubDate), this.valid).then(e => { this.clear() })
    }
  }
  actualizarVersion(id) {
    // console.log('actualizar...', this.pubDate)
    this.servicio.editarVersion(this.apkFilename, id, this.id, new Date(this.pubDate), this.valid)
    this.clear()
    this.editt = false
  }
  clear() {
    this.apkFilename = ''
    this.id = ''
    this.pubDate = ''
    this.valid = true
  }
  cancelar() {
    this.clear()
    this.editt = false
  }
  editarVersion(id) {
    // console.log('comp editar version', id)
    const temp = this.dataSource.data
    temp.forEach(version => {
      if (version.id === id) {
        this.apkFilename = version.apkFilename
        this.id = version.id
        const temp = $('#pubdateid')
        const dateTemp = new Date(version.pubDate.seconds * 1000).toISOString().substr(0, 10)
        temp.val(dateTemp)
        this.pubDate = dateTemp
        // console.log('pubdateeeee', this.pubDate)
        // console.log('temp val', temp.val())
        this.valid = version.valid
        this.editt = true
        this.idedit = id
      }
    })

  }
  eliminarVersion(id) {
    // console.log('conpo, eliminar', id)
    const r = confirm('¿Esta seguro de eliminar?')
    if (r === true) {
      this.servicio.elmininarVersion(id)
    } else {
    }
  }
}
