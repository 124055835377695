import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-cuentas',
  templateUrl: './cuentas.component.html',
  styleUrls: ['./cuentas.component.css']
})
export class CuentasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
