// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDbw6M5FmfdbBR5KVWJVsgqTnXmloI4Bjc',
    authDomain: 'fotos-productos-pvm.firebaseapp.com',
    databaseURL: 'https://fotos-productos-pvm.firebaseio.com',
    projectId: 'fotos-productos-pvm',
    storageBucket: 'fotos-productos-pvm.appspot.com',
    messagingSenderId: '943154712428'
  }
  // firebase: {
  //   apiKey: 'AIzaSyCjrFowZ2ZMLrxHi3ZWYv8xuo-pTLz2F9s',
  //   authDomain: 'test-productos.firebaseapp.com',
  //   databaseURL: 'https://test-productos.firebaseio.com',
  //   projectId: 'test-productos',
  //   storageBucket: 'test-productos.appspot.com',
  //   messagingSenderId: '961441091944'
  // }
}
