import { Component, OnInit } from '@angular/core';
import { AuthFirebaseService } from 'src/app/servicios/auth-firebase.service';
import { User } from 'src/app/user';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  user: User
  step = 0;
  myVar=''
  constructor(private auth: AuthFirebaseService) { }

  ngOnInit() {
    this.auth.getRol().forEach(f => { this.user = f })
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  async backupBaseDeDatos() {
    const url = 'https://backupfirestore-p7zxvxibta-uc.a.run.app';
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText);
      }
      const data = await response.text();
      console.log('Response from API:', data);
      return data; 
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      throw error; 
    }
  }

  async generar(){
    this.myVar = await this.backupBaseDeDatos();
  }

}
