import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';


@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) { }


  /////       EMAILS
  getEmails() {
    return this.afs.collection('cuentas/emailsRecepcion/emails').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data()
        const id = a.payload.doc.id
        return { id, ...data }
      })))
  }

  AgregarEmail(emaill) {
    return this.afs.collection('cuentas/emailsRecepcion/emails').add({
      email: emaill
    })
  }
  setEmail(id, emaill) {
    return this.afs.doc('cuentas/emailsRecepcion/emails/' + id).update({
      email: emaill
    })
  }
  deleteEmail(id) {
    return this.afs.doc('cuentas/emailsRecepcion/emails/' + id).delete()
  }

  /////     FTP

  getFtp() {
    return this.afs.doc('cuentas/ftp').valueChanges()
  }
  setFtp(ftp) {
    return this.afs.doc('cuentas/ftp').update({
      host: ftp.host,
      puerto: ftp.puerto,
      usuario: ftp.usuario,
      password: ftp.password
    })
  }
  /////     correo de envio de emails

  getEmailE() {
    return this.afs.doc('cuentas/emailEnvio').valueChanges()
  }
  setEmailE(ftp) {
    return this.afs.doc('cuentas/emailEnvio').update({
      email: ftp.email,
      password: ftp.password
    })
  }
  delete() {
    return  this.afs.collection('log').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    )
  }
  getRegistros() {
    return this.afs.collection('registros').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data()
        const id = a.payload.doc.id
        return { id, ...data }
      })))
  }
  borrarArchivo(file) {
    // console.log(file)
    this.storage.storage.ref(file.nombre).delete()
    this.afs.collection('registros').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    ).subscribe(data => {
      data.forEach(item => {
        if (file.id === item.id) {
          const refdoc = this.afs.firestore.collection('registros').doc(item.id).delete().then(e => {
            // console.log('e ', e)
          }).catch(c => {
            // console.log('c ', c)
          })
        }
      })
    })
  }
}
