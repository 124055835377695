import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class VersionesService {


  constructor(private afs: AngularFirestore) { }

  getVersiones() {
    // return this.afs.collection('versions').valueChanges()
    return this.afs.collection('versions', ref => ref.orderBy('pubDate', 'desc')).valueChanges()
  }
  editarVersion(filename, idOld, idNew, date, valid) {
    if (idOld === idNew) {
      return this.afs.collection('versions').doc(idOld).ref.update({
        apkFilename: filename,
        pubDate: date,
        valid: valid
      })
    } else {
      this.elmininarVersion(idOld)
      this.agregarVersion(filename, idNew, date, valid)
    }

    // get().then(e => {
    //   e.docs[0].ref.update({
    //     codigo: codigo,
    //     nombre: nombre,
    //     email: email,
    //   })
    // })
  }
  agregarVersion(apkname, id, date, val) {
    // console.log('servicio, agregar')
    return this.afs.collection('versions').doc(id).set({ apkFilename: apkname, id: id, pubDate: date, valid: val })
  }
  elmininarVersion(id) {
    // console.log('servicio eliminar', id)
    return this.afs.collection('versions').doc(id).delete()
  }
}
