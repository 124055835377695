import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-log-base',
  templateUrl: './log-base.component.html',
  styleUrls: ['./log-base.component.css']
})
export class LogBaseComponent implements OnInit {


  displayedColumns: string[] = ['select', 'nombre'];
  dataSource
  selection = new SelectionModel(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  constructor(private servicio: ConfiguracionService) { }

  ngOnInit() {
    this.servicio.getRegistros().subscribe(data => {
      console.log('9999999999 ', data)
      this.dataSource = new MatTableDataSource(data);
    })
  }
  borrar() {
    if (confirm('¿Esta Seguro que desea Eliminar los registros?')) {
      console.log(this.selection.selected)
      this.selection.selected.forEach(archivo => {
        this.servicio.borrarArchivo(archivo)
      })
    } else {
      console.log('no borrado')
    }
  }
}
