import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { AdminComponent } from './component/admin/admin.component';
import { EstadisticaComponent } from './component/estadistica/estadistica.component';
import { ConfiguracionComponent } from './component/configuracion/configuracion.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule, MatTableModule, MatSortModule, MatInputModule, MatSelectModule, MatDividerModule, MatButtonModule, MatPaginatorIntl, MatExpansionModule, MatIconModule, MatTabsModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatListModule, MatTooltipModule } from '@angular/material';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { AuthGuard } from './auth.guard';
import { getDutchPaginatorIntl } from './servicios/paginator-intl';
import { CuentasComponent } from './component/tabs/cuentas/cuentas.component';
import { FtpComponent } from './component/tabs/ftp/ftp.component';
import { EmailNComponent } from './component/tabs/email-n/email-n.component';
import { EmailEComponent } from './component/tabs/email-e/email-e.component';
import { BackupComponent } from './component/tabs/backup/backup.component';
import { DataBaseComponent } from './component/tabs/data-base/data-base.component';
import { LogBaseComponent } from './component/tabs/log-base/log-base.component';
import { ClienteComponent } from './component/cliente/cliente.component';
import { PopupComponent } from './component/popup/popup.component';
import { KeysPipe } from './keys.pipe';
import { VersionesComponent } from './component/versiones/versiones.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    EstadisticaComponent,
    ConfiguracionComponent,
    CuentasComponent,
    FtpComponent,
    EmailNComponent,
    EmailEComponent,
    BackupComponent,
    DataBaseComponent,
    LogBaseComponent,
    ClienteComponent,
    PopupComponent,
    KeysPipe,
    VersionesComponent,
  ],
  entryComponents: [PopupComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    MatButtonModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    MatDialogModule,
    MatListModule,
    MatTooltipModule,
  ],
  providers: [AuthGuard,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
