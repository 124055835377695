import { Component, OnInit } from '@angular/core';
import { AuthFirebaseService } from 'src/app/servicios/auth-firebase.service';
import { User } from 'src/app/user';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  user: User

  constructor(private auth: AuthFirebaseService) { }

  ngOnInit() {
    this.auth.getRol().forEach(f => { this.user = f })
  }

  salir() {
    this.auth.signOut()
  }
}
