import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-data-base',
  templateUrl: './data-base.component.html',
  styleUrls: ['./data-base.component.css']
})
export class DataBaseComponent implements OnInit {

  constructor(private afs: AngularFirestore, private servicio: ConfiguracionService) { }

  ngOnInit() {
  }

  BorrarBaseDeDatos() {
    if (confirm('¿Esta Seguro que desea Eliminar los registros?')) {
      let countLenght
      let filtro
      const res = this.servicio.delete()
      const dataSuscrip = res.subscribe(data => {
        countLenght = data.length
        filtro = data
        const batchs = []
        let countBatch = 0
        // starting batch firestore jobs
        let batch = this.afs.firestore.batch()
        // set batch
        filtro.forEach(item => {
          console.log('count  ', countLenght)
          const refdoc = this.afs.firestore.collection('log').doc(item.id)
          countLenght--;
          if (countBatch++ < 500) {
            batch.delete(refdoc)
          } else {
            batchs.push(batch)
            countBatch = 0
            batch = this.afs.firestore.batch()
            batch.delete(refdoc)
          }
          if (countLenght <= 0) {
            setTimeout(() => {
              batchs.push(batch)
              let tempsize = batchs.length
              batchs.forEach(b => {
                tempsize--
                if (tempsize <= 0) {

                  b.commit().then(function () {
                    dataSuscrip.unsubscribe()
                    console.log('borrado...')
                    alert('Base de datos Borrada')
                  })
                }
              })
            }, 500);
          }
        })
      })
    } else {
      console.log('no borrado')
    }
  }
}
