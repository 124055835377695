import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

export interface RegLog {
  id: string
  Fecha: Date;
  ImagenId: number;
  SourceId: string;
  Usuario: number;
}


@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {

  respCollection: AngularFirestoreCollection<RegLog>;
  reps: Observable<RegLog[]>;
  constructor(private afs: AngularFirestore) {
  }

  getData(f1: Date, f2: Date, limit) {
    if (!f1) {
      f1 = new Date(0)
    }
    if (!f2) {
      f2 = new Date()
    }
    // try {
    // tslint:disable-next-line:max-line-length
    this.respCollection = this.afs.collection<RegLog>('log', ref => ref.where('Fecha', '>=', f1).where('Fecha', '<=', f2).orderBy('Fecha', 'desc').limit(limit))
    return this.respCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as RegLog;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    )


    // } catch (error) {
    //   console.log(error)
    // }
  }
  getData2(limit) {
    return this.afs.collection('log', ref => ref.orderBy('Fecha', 'desc').limit(limit)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as RegLog;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    )
  }

  deleteFiltered(filtro: RegLog[]) {
    const batchs = []
    let countBatch = 0
    let countLenght = filtro.length
    // starting batch firestore jobs
    let batch = this.afs.firestore.batch()

    // set batch
    filtro.forEach(item => {
      const refdoc = this.afs.firestore.collection('log').doc(item.id)
      countLenght--;
      if (countBatch++ < 500) {
        batch.delete(refdoc)
      } else {
        batchs.push(batch)
        countBatch = 0
        batch = this.afs.firestore.batch()
        batch.delete(refdoc)
      }
      if (countLenght <= 0) {
        setTimeout(() => {

          batchs.push(batch)
          let tempsize = batchs.length
          batchs.forEach(b => {
            tempsize--
            b.commit().then(function () {
              // console.log('borrado...')
            })
          })
        }, 500);
      }
    })
  }
}
