import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';


@Component({
  selector: 'app-email-n',
  templateUrl: './email-n.component.html',
  styleUrls: ['./email-n.component.css']
})
export class EmailNComponent implements OnInit {
  tabLoadTimes: Date[] = [];
  displayedEmail: string[] = ['email', 'action'];

  dataEmail
  editando = false
  agregarEmail = new FormControl();
  editarEmail = new FormControl();
  actualizarEmail = new FormControl();
  editmail


  constructor(private servicio: ConfiguracionService) { }

  ngOnInit() {
    this.servicio.getEmails().subscribe(data => {
      this.dataEmail = data
    })
    // this.editmail.email = ''
  }

  getTimeLoaded(index: number) {
    if (!this.tabLoadTimes[index]) {
      this.tabLoadTimes[index] = new Date();
    }

    return this.tabLoadTimes[index];
  }
  AgregarEmail() {
    this.servicio.AgregarEmail(this.agregarEmail.value)
      .then(t => {
        console.log('Document successfully written! ', t)
        this.agregarEmail.setValue('')
      })
      .catch(error => {
        console.log('Error writing document: ', error)
      });
  }
  editaremail(id) {
    // this.editmail =  this.dataEmail.
    this.editando = true
    console.log('111')
    this.dataEmail.forEach(e => {
      console.log('222')
      if (e.id === id) {
        console.log('333')
        this.editmail = e
      }
    });
  }
  cancelarEmail() {
    this.editando = false
    this.editmail = null
  }
  actualizarEmailF() {
    this.servicio.setEmail(this.editmail.id, this.actualizarEmail.value)
      .then(t => {
        console.log('Document successfully update! ', t)
        this.agregarEmail.setValue('')
        this.editando = false
        this.editmail = null
      })
      .catch(error => {
        console.log('Error update document: ', error)
      });
  }
  deletemail(id) {
    this.servicio.deleteEmail(id)
  }
}
